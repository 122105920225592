import { IApiFilters, IUser, IRequestMethod, IStatus, IUserClientRole, IPrivilege } from "@/lib/interfaces";
import { ObjectTypeAPI, omniApi } from "../../omniApi";
import { queryParams } from "./riskRadar";

const { Users } = ObjectTypeAPI

interface IUserParams {
  user?: string;
}

export interface IUserApiResponse {
  status: IStatus;
  data: Array<IUser>
}

interface IUserRolesParams {
  client: string;
  user?: string;
}
interface IUserRolesApiResponse {
  status: IStatus;
  data: Array<IUserClientRole>
}

interface IUserPrivilegesParams {
  client: string;
  user: string;
}
interface IUserPrivilegesApiResponse {
  status: IStatus;
  lastRefresh?: string
  data: Array<IPrivilege>
}

export const userAPI = omniApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getIUsers: builder.query<IUserApiResponse, IUserParams & IApiFilters>({
      query: ({ filters }) => ({
        url: `/users${filters ? `?data=${queryParams({ filters })}` : ``}`,
        method: "get",
      }),
    }),
    getIUser: builder.query<IUserApiResponse, IUserParams & IApiFilters>({
      query: ({ user, filters }) => ({
        url: `/user/${user}${filters ? `?data=${queryParams({ filters })}` : ``}`,
        method: "get",
      }),
    }),
    iUser: builder.mutation<IUserApiResponse, IUserParams & IRequestMethod & { body?: IUser }>({
      query: ({ user, method = 'put', body }) => ({
        url: `/user/${user}`,
        method,
        body
      }),
    }),

    //Additional utilities:
    getUserRoles: builder.query<IUserRolesApiResponse, IUserRolesParams & IApiFilters>({
      query: ({ client, filters }) => ({
        url: `/client/${client}/userClientRoles${filters ? `?data=${queryParams({ filters })}` : ``}`,
        method: "get",
      }),
    }),

    getGrantedPrivileges: builder.query<IUserPrivilegesApiResponse, { client: string } & IApiFilters>({
      query: ({ client, filters }) => ({
        url: `/client/${client}/grantedPrivileges${filters ? `?data=${queryParams({ filters })}` : ``}`,
        method: "get",
      }),
    }),

    //extra method for password reset
    getUserPR: builder.query<IUserApiResponse, { token: string } & IUserParams & IApiFilters>({
      query: ({ user, filters, token }) => ({
        url: `/user/${user}${filters ? `?data=${queryParams({ filters })}` : ``}`,
        method: "get",
        headers: {
          "x-hub2-token": token,
          "Content-type": "application/json",
        }
      }),
    }),

    userPR: builder.mutation<IUserApiResponse, IUserParams & IRequestMethod & { body?: IUser } & { token: string }>({
      query: ({ user, method = 'put', body, token }) => ({
        url: `/user/${user}`,
        method,
        body,
        headers: {
          "x-hub2-token": token,
          "Content-type": "application/json",
        }
      }),
    }),
  }),
});

export const {
  useUserPRMutation,
  useLazyGetUserPRQuery,

  useLazyGetIUserQuery,
  useIUserMutation,
  useGetIUserQuery,
  useGetIUsersQuery,
  useLazyGetIUsersQuery,
  useGetUserRolesQuery,
  useGetGrantedPrivilegesQuery
} = userAPI;
