import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { useStore } from "react-redux";
import { ISite, IBuilding, IClient, IFloor, ILayoutItem, ILoadingDisable, IObjectFavorite, IObjectLoading, ISubsystem, IUser, IClientRole, IPrivilege, IModuleAccessMap } from "../interfaces";
import { IContract } from "../services/endpoints/newAPI/customQueries";

export interface AppSliceState {
  moduleAccessMap: IModuleAccessMap,
  privileges: IPrivilege[];
  privs: any;
  user: IUser;
  users: IUser[];
  clientUsers: IUser[];
  loading: IObjectLoading,
  favourite: IObjectFavorite,
  skip: boolean;
  suppliers: Array<IClient>;
  sites: ISite[];
  clients: Array<IClient>;
  contracts: Array<IContract>;
  buildings: Array<IBuilding>;
  roles: Array<IClientRole>;
  floors: Array<IFloor>;
  fcDashboardResolution: {
    width: number;
    height: number;
  };
  fullScreenLayout: Array<ILayoutItem>;
  subsystems: Array<ISubsystem>;
}

type AppType = {
  moduleAccessMap: IModuleAccessMap,
  sites: [],
  privileges: IPrivilege[];
  privs: any;
  user: IUser | undefined;
  users: IUser[];
  clientUsers: IUser[];
  loading: IObjectLoading,
  favourite: IObjectFavorite,
  skip: boolean;
  subsystems: Array<ISubsystem>;
  suppliers: Array<IClient>;
  clients: Array<IClient>;
  contracts: Array<IContract>;
  dashboards: [];
  buildings: Array<IBuilding>;
  roles: Array<IClientRole>;
  floors: Array<IFloor>;
  fullScreenLayout: Array<ILayoutItem>;
  fcDashboardResolution: {
    width: number;
    height: number;
  };
};

const initialState: AppType = {
  moduleAccessMap: {},
  sites: [],
  privileges: [],
  privs: {},
  user: undefined,
  subsystems: [],
  favourite: {
    site: '0',
    client: '0',
    contract: '0',
    building: '0'
  },
  loading: {
    clients: true,
    contracts: true,
    buildings: true,
    subsystems: true,
    roles: true,
    privileges: true,
    users: true,
    clientUsers: true,
  },
  fullScreenLayout: [],
  skip: true,
  clients: [],
  suppliers: [],
  contracts: [],
  dashboards: [],
  buildings: [],
  floors: [],
  roles: [],
  fcDashboardResolution: {
    width: 0,
    height: 0,
  },
};

export const apiDataSlice = createSlice({
  name: "apiData",
  initialState,
  reducers: {
    apiLogout: () => initialState,
    setPrivileges(state: AppSliceState, action: PayloadAction<{ privileges: IPrivilege[] }>) {
      state.privileges = action.payload.privileges;

      //Generate structural equivalent of above:
      const pmap = {};
      action?.payload?.privileges?.forEach((p) => {
        const ps = String(p?.name).split(".");

        var pp = pmap;
        var key;
        while (key = ps.shift()) {
          if (!pp[key]) {
            pp[key] = {};
          }
          pp = pp[key];
        }
      });
      state.privs = pmap;
    },

    setFullscreenLayout(
      state: AppSliceState,
      action: PayloadAction<{ layout: Array<ILayoutItem> }>
    ) {
      state.fullScreenLayout = action.payload.layout;
    },
    disableLoading(state: AppSliceState, action: PayloadAction<{ type: ILoadingDisable }>) {
      const { type } = action.payload;
      switch (type) {
        case 'clients': { state.loading.clients = false; break; }
        case 'contracts': { state.loading.contracts = false; break; }
        case 'buildings': { state.loading.buildings = false; break; }
        case 'subsystems': { state.loading.subsystems = false; break; }
        case 'roles': { state.loading.roles = false; break; }
        case 'privileges': { state.loading.privileges = false; break; }
        case 'users': { state.loading.users = false; break; }
        case 'clientUsers': { state.loading.clientUsers = false; break; }
      }
    },
    setFavourite(state: AppSliceState, action: PayloadAction<{ type: string, value: string }>) {
      const { type, value } = action.payload;


      switch (type) {
        case 'client': { state.favourite.client = value; break; }
        case 'contract': { state.favourite.contract = value; break; }
        case 'building': { state.favourite.building = value; break; }
        case 'site': { state.favourite.site = value; break; }
      }

      if (window.sessionStorage) {
        window.sessionStorage.setItem('hub2-favs', JSON.stringify(state.favourite));
      }
    },
    setSkip(state: AppSliceState, action: PayloadAction<{ skip: boolean }>) {
      state.skip = action.payload.skip;
    },
    setSubsystems(state: AppSliceState, action: PayloadAction<{ subsystems: Array<ISubsystem>; }>) {
      state.subsystems = action.payload.subsystems;
    },
    setFcDashboardResolution(
      state: AppSliceState,
      action: PayloadAction<{ res: { width: number; height: number } }>
    ) {
      state.fcDashboardResolution.width = action.payload.res.width;
      state.fcDashboardResolution.height = action.payload.res.height;
    },

    setClients(
      state: AppSliceState,
      action: PayloadAction<{ data: Array<IClient> }>
    ) {
      state.suppliers = action.payload.data.filter(c => c.isSupplier === "1")
      state.clients = action.payload.data.filter((c) => {
        if (+c.isSupplier === 1 && +c.isContracted === 0) {
          return false;
        }
        return true
      }
      )
    },
    setClientUsers(
      state: AppSliceState,
      action: PayloadAction<{ data: Array<IUser> }>
    ) {
      state.clientUsers = action.payload.data;
    },
    setUsers(
      state: AppSliceState,
      action: PayloadAction<{ data: Array<IUser> }>
    ) {
      state.users = action.payload.data;
    },
    setContracts(
      state: AppSliceState,
      action: PayloadAction<{ data: Array<IContract> }>
    ) {
      state.contracts = action.payload.data;
    },
    setModuleAccessMap(
      state: AppSliceState,
      action: PayloadAction<{ moduleAccessMap: IModuleAccessMap }>
    ) {
      state.moduleAccessMap = action.payload.moduleAccessMap;
    },
    setSites(
      state: AppSliceState,
      action: PayloadAction<{ sites: ISite[] }>
    ) {
      state.sites = action.payload.sites;
    },
    setBuildings(
      state: AppSliceState,
      action: PayloadAction<{ data: Array<IBuilding> }>
    ) {
      state.buildings = action.payload.data;
    },
    setFloors(
      state: AppSliceState,
      action: PayloadAction<{ data: Array<IFloor> }>
    ) {
      state.floors = action.payload.data;
    },
    setRoles(state: AppSliceState, action: PayloadAction<{ data: Array<IClientRole> }>) {
      state.roles = action.payload.data;
    }
  },
});

export const {
  apiLogout,
  setSkip,
  setContracts,
  setBuildings,
  setFcDashboardResolution,
  setFullscreenLayout,
  setFloors,
  setClients,
  setRoles,
  disableLoading,
  setFavourite,
  setSubsystems,
  setPrivileges,
  setSites,
  setUsers,
  setClientUsers,
  setModuleAccessMap
} = apiDataSlice.actions;

export default apiDataSlice.reducer;
