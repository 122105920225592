import { CircularProgress, Flex } from "@chakra-ui/react";

type Props = {
  size?: string;
  color?: string;
  thickness?: string
}


export const Loader = ({
  size = '160px',
  thickness = '3px',
  color = 'blue.400'
}: Props) => {
  return (
    <Flex
      justifyContent="center" alignContent="center">
      <CircularProgress
        thickness={thickness}
        size={size}
        capIsRound={true}
        isIndeterminate
        color={color}
      />
    </Flex>
  );
};
