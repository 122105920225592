import { MenuIconProps } from "@/lib/types"

export const MainPageIcon = ({ fill, height = 20, width = 20 }: MenuIconProps) => {
    return <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1964_25809)">
            <path d="M14.4506 5.66843L9.71 0.927188C9.12328 0.342181 8.32854 0.0136719 7.5 0.0136719C6.67147 0.0136719 5.87673 0.342181 5.29001 0.927188L0.549382 5.66843C0.374648 5.84205 0.236114 6.04862 0.141809 6.27618C0.0475045 6.50373 -0.000694 6.74774 7.54967e-06 6.99406V13.1297C7.54967e-06 13.627 0.197552 14.1039 0.549182 14.4555C0.900813 14.8071 1.37773 15.0047 1.87501 15.0047H13.125C13.6223 15.0047 14.0992 14.8071 14.4508 14.4555C14.8025 14.1039 15 13.627 15 13.1297V6.99406C15.0007 6.74774 14.9525 6.50373 14.8582 6.27618C14.7639 6.04862 14.6254 5.84205 14.4506 5.66843ZM9.375 13.7547H5.62501V11.2959C5.62501 10.7986 5.82255 10.3217 6.17418 9.97011C6.52581 9.61848 7.00272 9.42093 7.5 9.42093C7.99729 9.42093 8.4742 9.61848 8.82583 9.97011C9.17746 10.3217 9.375 10.7986 9.375 11.2959V13.7547ZM13.75 13.1297C13.75 13.2954 13.6842 13.4544 13.5669 13.5716C13.4497 13.6888 13.2908 13.7547 13.125 13.7547H10.625V11.2959C10.625 10.4671 10.2958 9.67227 9.70971 9.08622C9.12366 8.50017 8.32881 8.17093 7.5 8.17093C6.6712 8.17093 5.87635 8.50017 5.2903 9.08622C4.70425 9.67227 4.37501 10.4671 4.37501 11.2959V13.7547H1.87501C1.70925 13.7547 1.55028 13.6888 1.43307 13.5716C1.31586 13.4544 1.25001 13.2954 1.25001 13.1297V6.99406C1.25059 6.82842 1.31638 6.66967 1.43313 6.55218L6.17376 1.81281C6.52607 1.46214 7.00292 1.26528 7.5 1.26528C7.99709 1.26528 8.47394 1.46214 8.82625 1.81281L13.5669 6.55406C13.6832 6.67109 13.7489 6.82907 13.75 6.99406V13.1297Z" fill={fill} />
        </g>
        <defs>
            <clipPath id="clip0_1964_25809">
                <rect width="15" height="15" fill="white" />
            </clipPath>
        </defs>
    </svg>

}


export default MainPageIcon;