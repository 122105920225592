import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LoginResponse, User, LogoutResponse } from "@/lib/types";
import { IAuthorityToAccess, IClient, IEPermit, IOpenWeatherMapResponse, IStatus } from "../interfaces";

export interface IUser {
  id?: string,
  active?: string,
  boxAPIUser: number,
  minimalAPIUser: string,
  name?: string,
  firstname?: string;
  lastname?: string;
  username?: string,
  email?: string,
  superuser?: string,
  passwordMD5?: string;
  passwordSalt?: string;
  fileHash: string;
  selfie: string;
  primaryClient?: string;
  company: IClient | undefined;
  logRocket?: string;

}


interface SelfResponse {
  status: IStatus,
  data: IUser
}

interface ClientsCheck {
  status: IStatus,
  data: IClient[]
}

interface InductionInvitationResponse {
  status: IStatus;
  user: {
    firstname: string,
    lastname: string,
    email: string,
    active: string;
  }
}

export const defaultHeaders = {
  'Strict-Transport-Security': 'max-age=31536000; includeSubDomains'
}

export const authApi = createApi({
  reducerPath: "/api/auth",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api",
    prepareHeaders: (headers, { getState }) => {
      Object.keys(defaultHeaders).map(headerName => {
        // headers.append(headerName, defaultHeaders[headerName])
      })
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getWeather: builder.query<
      { data: IOpenWeatherMapResponse },
      { latitude: string; longitude: string }
    >({
      query: ({ latitude, longitude }) => ({
        url: `weather`,
        method: "POST",
        body: {
          latitude,
          longitude,
        },
      }),
    }),

    login: builder.mutation<LoginResponse, User>({
      query(body) {
        return {
          url: "/login",
          method: "POST",
          body,
        };
      },
    }),

    tokenValidation: builder.mutation<LoginResponse, User>({
      query(body) {
        return {
          url: "/tokenValidation",
          method: "POST",
          body,

        };
      },
    }),

    genAccount: builder.mutation<LoginResponse, User>({
      query(body) {
        return {
          url: "/genAccount",
          method: "POST",
          body,
        };
      },
    }),
    getInduction: builder.query<InductionInvitationResponse, { hash: string, client: string }>({
      query(body) {
        return {
          url: "/getInduction",
          method: "POST",
          body,
        };
      },
    }),
    getAuthAccessCheck: builder.query<{
      status: IStatus,
      auth: IAuthorityToAccess,
      user: string
    }, { uuid: string, client: string }>({
      query(body) {
        return {
          url: "/getAuthAccessCheck",
          method: "POST",
          body,
        };
      },
    }),
    getEpermitCheck: builder.query<{
      status: IStatus,
      epermit: IEPermit,
      dayEpermit: IEPermit,
      user: string
    }, { uuid: string, client: string, dayPermitId: string }>({
      query(body) {
        return {
          url: "/getEpermitCheck",
          method: "POST",
          body,
        };
      },
    }),
    getEmergencyEpermitCheck: builder.query<{
      status: IStatus,
      epermit: IEPermit,
      dayEpermit: IEPermit,
      user: string
    }, { uuid: string, client: string, dayPermitId: string }>({
      query(body) {
        return {
          url: "/getEmergencyEpermitCheck",
          method: "POST",
          body,
        };
      },
    }),
    inductionUpdate: builder.mutation<InductionInvitationResponse, { client: string; hash: string; position: string; mobile: string; password: string; }>({
      query(body) {
        return {
          url: "/inductionUpdate",
          method: "POST",
          body,
        };
      },
    }),
    buildingAccess: builder.mutation<{ status: IStatus, building: string }, { client: string; building: string; user: string; isManager: number; denyAccess: number; }>({
      query(body) {
        return {
          url: "/buildingAccess",
          method: "POST",
          body,
        };
      },
    }),

    passwordReset: builder.mutation<any, User & { resetUrl: string, auth?: string }>({
      query(body) {
        return {
          url: "/resetPassword",
          method: "POST",
          body,
        };
      },
    }),

    resetPasswordRequest: builder.mutation<any, { userId: string, token: string }>({
      query(body) {
        return {
          url: "/resetPasswordRequest",
          method: "POST",
          body,
        };
      },
    }),

    logout: builder.mutation<LogoutResponse, void>({
      query() {
        return {
          url: "/logout",
          method: "POST",
        };
      },
    }),
    tokenRefresh: builder.query<LoginResponse, void>({
      query: () => ({
        url: "/refresh_token",
        credentials: "include",
        method: "post",
      }),
    }),
    getUserDetails: builder.query<SelfResponse, { accessToken: string }>({
      query: ({ accessToken }) => ({
        url: `${process.env['NEXT_PUBLIC_SERVER_URL']}/rest/self`,
        credentials: "include",
        method: "get",
        headers: {
          'X-HUB2-TOKEN': accessToken
        }
      }),
    }),
    getClients: builder.query<ClientsCheck, { accessToken: string }>({
      query: ({ accessToken }) => ({
        url: `${process.env['NEXT_PUBLIC_SERVER_URL']}/rest/dbo/clients`,
        credentials: "include",
        method: "get",
        headers: {
          'X-HUB2-TOKEN': accessToken
        }
      }),
    }),
    getMatterportModel: builder.query<ClientsCheck, { spaceId: string }>({
      query: ({ spaceId }) => ({
        url: `/getMatterportModel`,
        credentials: "include",
        method: "get",
        headers: {
          spaceId
        }
      }),
    }),
  }),
});

export const {
  useGetWeatherQuery,
  useLoginMutation,
  useTokenValidationMutation,
  useGenAccountMutation,
  usePasswordResetMutation,
  useTokenRefreshQuery,
  useLogoutMutation,
  useLazyGetUserDetailsQuery,
  useGetUserDetailsQuery,
  useResetPasswordRequestMutation,
  useGetInductionQuery,
  useInductionUpdateMutation,
  useBuildingAccessMutation,
  useGetEpermitCheckQuery,
  useGetEmergencyEpermitCheckQuery,
  useGetAuthAccessCheckQuery,
  useLazyGetClientsQuery,
  useGetMatterportModelQuery
} = authApi;
