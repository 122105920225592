import {
  extendTheme,
  theme as base,
  withDefaultVariant,
} from "@chakra-ui/react";
import { Button, } from './components/index'

const theme = extendTheme(
  {
    initialColorMode: "system",
    colors: {
      accentColor: "#FF7500",
      darkBG: "#111E34",
      fieldBgLight: "#F8FAFC",
      fieldBgDark: "#040016",
      lightBorder: '#1B1B36',
      darkBorder: "#78789C",
    },
    fonts: {
      heading: `Inter, ${base.fonts.heading}`,
      body: `Inter, ${base.fonts.body}`,
    },
    components: {
      // Button
    }
  },
  withDefaultVariant({
    variant: "filled",
    components: ["Input"],
  })
);

export default theme;
