import React, { useState, createContext } from 'react';
import '../public/style.css';
import '../public/print.css';
import Head from 'next/head';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import AuthWrapper from '@/components/AuthWrapper';
import store from '@/lib/store';
import { AppPropsWithLayout, NextPageWithLayout } from "../lib/types";
import { SpeedInsights } from "@vercel/speed-insights/next"
import { Analytics } from '@vercel/analytics/react';
// import { TourProvider } from '@reactour/tour'
import theme from '@/lib/chakra/theme';

type ThemeContextProps = {
  activeTheme: Record<string, any> | undefined;
  setActiveTheme: React.Dispatch<React.SetStateAction<Record<string, any>>> | undefined;
};

export const ThemeContext = createContext<ThemeContextProps>({
  activeTheme: undefined,
  setActiveTheme: undefined
});

function MyApp({
  Component,
  pageProps: { session, ...pageProps },
  router,
}: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  const [activeTheme, setActiveTheme] = useState(theme);

  return <Provider store={store} >
    <ChakraProvider theme={activeTheme} >
      <Head>
        <meta
          name="viewport"
          content="initial-scale=1, width=device-width,height=device-height,maximum-scale=1"
        />
      </Head>
      <AuthWrapper router={router}>
        <ThemeContext.Provider value={{ activeTheme, setActiveTheme }}>
          {getLayout(<Component {...pageProps} />)}
        </ThemeContext.Provider>
        {
          process.env.NODE_ENV === 'production' ? <>
            <SpeedInsights />
            <Analytics />
          </> : <></>
        }
      </AuthWrapper>
    </ChakraProvider>
  </Provider>
}

export default MyApp;
