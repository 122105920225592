import { MenuIconProps } from "@/lib/types"

export const CafmIcon = ({ fill, width, height }: MenuIconProps) => {
    return <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.26859 2.78906C1.21586 2.82715 0.0117537 2.61328 0.334019 3.19336C0.553746 3.51855 0.873082 3.76758 1.24222 3.90527C2.21781 4.26562 3.30179 3.76465 3.66214 2.78906H11.3643C11.502 3.1582 11.751 3.47754 12.0762 3.69726C12.9375 4.28027 14.1094 4.05469 14.6924 3.19336C14.7745 3.06738 14.8037 2.78906 14.3848 2.78906H13.7608C13.4151 2.78906 13.1368 2.50781 13.1368 2.16504C13.1368 1.81933 13.418 1.54101 13.7608 1.54101C13.8135 1.50293 15.0205 1.71679 14.6953 1.13672C14.4756 0.81152 14.1563 0.562497 13.7871 0.424801C12.8116 0.0644495 11.7276 0.565426 11.3672 1.54101H3.66507C3.52738 1.17187 3.27836 0.852536 2.95316 0.632809C2.0889 0.0468714 0.917027 0.272457 0.334019 1.13379C0.251988 1.25976 0.222691 1.53808 0.641637 1.53808H1.26566C1.61136 1.53808 1.88968 1.81933 1.88968 2.16211C1.89261 2.51074 1.61429 2.78906 1.26859 2.78906Z" fill={fill} />
        <path d="M4.4092 6.72949C4.24221 6.58007 4.01076 6.53027 3.79689 6.60351C3.58596 6.67675 3.42775 6.85546 3.38967 7.07812C3.31643 7.47656 3.126 7.83691 2.92385 8.21484C2.87697 8.30273 2.82717 8.39355 2.78029 8.4873C2.6924 8.65722 2.63088 8.82714 2.59865 8.99121C2.5635 9.1582 2.51662 9.38964 2.55471 9.6416C2.62795 10.1221 2.87697 10.5381 3.25197 10.8135C3.55373 11.0332 3.91701 11.1475 4.29787 11.1475C4.40334 11.1475 4.50881 11.1387 4.61428 11.1211C5.08596 11.0449 5.50783 10.7812 5.77736 10.4004C6.03225 10.0371 6.12893 9.60351 6.04983 9.17578C5.83889 8.02734 5.04787 7.30664 4.4092 6.72949ZM4.77248 9.68847C4.69338 9.7998 4.56447 9.8789 4.41799 9.90234C4.251 9.92871 4.09572 9.89941 3.97854 9.81445C3.86721 9.73242 3.79689 9.60937 3.77346 9.4541C3.77053 9.41308 3.79396 9.29589 3.80568 9.2373C3.81447 9.19921 3.83205 9.13476 3.87893 9.04394C3.92287 8.95898 3.96682 8.87695 4.01369 8.78906C4.09572 8.63671 4.18068 8.47558 4.26272 8.30566C4.55861 8.64257 4.76662 8.98242 4.84279 9.39843C4.86623 9.52441 4.81643 9.62695 4.77248 9.68847Z" fill={fill} />
        <path d="M14.0977 5.1709C13.9248 4.74902 13.6817 4.58789 13.2276 4.58789C12.0879 4.58789 2.94145 4.58789 1.79594 4.58789C1.35063 4.58789 1.1016 4.75488 0.93168 5.16504C0.612344 5.93848 0.416055 6.80273 0.325235 7.80176C0.254922 8.56934 0.263711 9.3457 0.345742 10.1045C0.448281 11.0479 0.644571 11.8564 0.943399 12.5742C1.1016 12.9551 1.35941 13.125 1.77543 13.125C1.99809 13.125 2.34965 13.125 2.79789 13.125V14.168C2.79789 14.5078 3.07328 14.7832 3.41313 14.7832C3.75297 14.7832 4.02836 14.5078 4.02836 14.168V13.125C4.21586 13.125 4.40922 13.125 4.60844 13.125V14.168C4.60844 14.5078 4.88383 14.7832 5.22367 14.7832C5.56352 14.7832 5.83891 14.5078 5.83891 14.168V13.125C6.29008 13.125 6.7559 13.125 7.22465 13.125C9.61527 13.125 12.1026 13.125 13.2539 13.125C13.67 13.125 13.9278 12.9551 14.086 12.5713C14.4082 11.7949 14.6075 10.9307 14.7012 9.92285C14.7803 9.05566 14.7627 8.18555 14.6514 7.33594C14.543 6.51855 14.3614 5.80957 14.0977 5.1709ZM13.4737 9.81152C13.4004 10.6025 13.2539 11.2881 13.0284 11.8945C11.4258 11.8945 7.94242 11.8945 5.22367 11.8945C3.82914 11.8945 2.63383 11.8945 1.99809 11.8945C1.79008 11.335 1.64945 10.7051 1.56742 9.96973C1.49418 9.29004 1.48832 8.5957 1.54984 7.91016C1.62016 7.11621 1.76664 6.42773 1.99516 5.81543C3.66508 5.81543 11.3672 5.81543 13.0254 5.81543C13.21 6.31055 13.3418 6.86133 13.4268 7.49707C13.5323 8.25586 13.5469 9.03516 13.4737 9.81152Z" fill={fill} />
        <path d="M11.7686 8.9707H8.19434C7.85449 8.9707 7.5791 9.24609 7.5791 9.58594C7.5791 9.92578 7.85449 10.2012 8.19434 10.2012H11.7686C12.1084 10.2012 12.3838 9.92578 12.3838 9.58594C12.3838 9.24609 12.1084 8.9707 11.7686 8.9707Z" fill={fill} />
        <path d="M11.7686 6.93164H8.19434C7.85449 6.93164 7.5791 7.20703 7.5791 7.54687C7.5791 7.88672 7.85449 8.16211 8.19434 8.16211H11.7686C12.1084 8.16211 12.3838 7.88672 12.3838 7.54687C12.3838 7.20703 12.1084 6.93164 11.7686 6.93164Z" fill={fill} />
    </svg>
}

export default CafmIcon;
