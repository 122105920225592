import { MenuIconProps } from "@/lib/types"

export const DashboardIcon = ({ fill }: MenuIconProps) => {
    return <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1160_11449)">
            <path d="M8.33337 7.5H5.83337V15H8.33337V7.5Z" fill={fill} />
            <path d="M14.1666 10H11.6666V15H14.1666V10Z" fill={fill} />
            <path d="M20 6.6665H17.5V14.9998H20V6.6665Z" fill={fill} />
            <path d="M13.44 6.69407C12.9399 6.69399 12.4603 6.49525 12.1067 6.14157L8.64335 2.68157L6.91669 4.41657L5.14502 2.64491L7.30752 0.482406C7.6673 0.138325 8.14594 -0.0537109 8.64377 -0.0537109C9.1416 -0.0537109 9.62024 0.138325 9.98002 0.482406L13.44 3.94157L17.0325 0.349073L18.8009 2.11741L14.7759 6.14157C14.6006 6.31714 14.3923 6.45634 14.1631 6.55116C13.9338 6.64597 13.6881 6.69454 13.44 6.69407V6.69407Z" fill={fill} />
            <path d="M20 20H2.91667C2.14312 20 1.40125 19.6927 0.854272 19.1457C0.307291 18.5987 0 17.8569 0 17.0833L0 0H2.5V17.0833C2.5 17.1938 2.5439 17.2998 2.62204 17.378C2.70018 17.4561 2.80616 17.5 2.91667 17.5H20V20Z" fill={fill} />
        </g>
        <defs>
            <clipPath id="clip0_1160_11449">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
}

export default DashboardIcon;