import { MenuIconProps } from "@/lib/types"

export const HomeIcon = ({ fill }: MenuIconProps) => {
    return <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_415_436)">
            <path d="M14.4506 5.66813L9.71 0.926882C9.12328 0.341876 8.32854 0.0133667 7.5 0.0133667C6.67147 0.0133667 5.87673 0.341876 5.29001 0.926882L0.549382 5.66813C0.374648 5.84174 0.236114 6.04832 0.141809 6.27587C0.0475045 6.50342 -0.000694 6.74743 7.54967e-06 6.99375V13.1294C7.54967e-06 13.6267 0.197552 14.1036 0.549182 14.4552C0.900813 14.8068 1.37773 15.0044 1.87501 15.0044H13.125C13.6223 15.0044 14.0992 14.8068 14.4508 14.4552C14.8025 14.1036 15 13.6267 15 13.1294V6.99375C15.0007 6.74743 14.9525 6.50342 14.8582 6.27587C14.7639 6.04832 14.6254 5.84174 14.4506 5.66813ZM9.375 13.7544H5.62501V11.2956C5.62501 10.7983 5.82255 10.3214 6.17418 9.9698C6.52581 9.61817 7.00272 9.42063 7.5 9.42063C7.99729 9.42063 8.4742 9.61817 8.82583 9.9698C9.17746 10.3214 9.375 10.7983 9.375 11.2956V13.7544ZM13.75 13.1294C13.75 13.2951 13.6842 13.4541 13.5669 13.5713C13.4497 13.6885 13.2908 13.7544 13.125 13.7544H10.625V11.2956C10.625 10.4668 10.2958 9.67197 9.70971 9.08592C9.12366 8.49987 8.32881 8.17063 7.5 8.17063C6.6712 8.17063 5.87635 8.49987 5.2903 9.08592C4.70425 9.67197 4.37501 10.4668 4.37501 11.2956V13.7544H1.87501C1.70925 13.7544 1.55028 13.6885 1.43307 13.5713C1.31586 13.4541 1.25001 13.2951 1.25001 13.1294V6.99375C1.25059 6.82812 1.31638 6.66937 1.43313 6.55188L6.17376 1.81251C6.52607 1.46184 7.00292 1.26497 7.5 1.26497C7.99709 1.26497 8.47394 1.46184 8.82625 1.81251L13.5669 6.55375C13.6832 6.67078 13.7489 6.82877 13.75 6.99375V13.1294Z" fill={fill} />
        </g>
        <defs>
            <clipPath id="clip0_415_436">
                <rect width="15" height="15" fill="white" />
            </clipPath>
        </defs>
    </svg>
}

export default HomeIcon;