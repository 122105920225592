import { MenuIconProps } from "@/lib/types"

export const BoxInstanceIcon = ({ fill }: MenuIconProps) => {
    return <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1311_11910)">
            <path d="M5.625 0H1.875C1.37772 0 0.900805 0.197544 0.549175 0.549175C0.197544 0.900805 0 1.37772 0 1.875L0 5.625H3.125V6.875H1.875V8.125H5.625V6.875H4.375V5.625H7.5V1.875C7.5 1.37772 7.30246 0.900805 6.95083 0.549175C6.59919 0.197544 6.12228 0 5.625 0V0ZM6.25 4.375H1.25V1.875C1.25 1.70924 1.31585 1.55027 1.43306 1.43306C1.55027 1.31585 1.70924 1.25 1.875 1.25H5.625C5.79076 1.25 5.94973 1.31585 6.06694 1.43306C6.18415 1.55027 6.25 1.70924 6.25 1.875V4.375ZM13.125 6.875H10.625C10.1277 6.875 9.65081 7.07254 9.29917 7.42417C8.94754 7.77581 8.75 8.25272 8.75 8.75V15H15V8.75C15 8.25272 14.8025 7.77581 14.4508 7.42417C14.0992 7.07254 13.6223 6.875 13.125 6.875V6.875ZM13.75 13.75H10V8.75C10 8.58424 10.0658 8.42527 10.1831 8.30806C10.3003 8.19085 10.4592 8.125 10.625 8.125H13.125C13.2908 8.125 13.4497 8.19085 13.5669 8.30806C13.6842 8.42527 13.75 8.58424 13.75 8.75V13.75ZM10.625 3.125H8.75V1.875H10.625C11.1223 1.875 11.5992 2.07254 11.9508 2.42417C12.3025 2.77581 12.5 3.25272 12.5 3.75V5.625H11.25V3.75C11.25 3.58424 11.1842 3.42527 11.0669 3.30806C10.9497 3.19085 10.7908 3.125 10.625 3.125ZM5 11.875H7.5V13.125H5C4.50272 13.125 4.02581 12.9275 3.67417 12.5758C3.32254 12.2242 3.125 11.7473 3.125 11.25V9.375H4.375V11.25C4.375 11.4158 4.44085 11.5747 4.55806 11.6919C4.67527 11.8092 4.83424 11.875 5 11.875ZM12.5 12.5C12.5 12.6236 12.4633 12.7445 12.3947 12.8472C12.326 12.95 12.2284 13.0301 12.1142 13.0774C12 13.1247 11.8743 13.1371 11.7531 13.113C11.6318 13.0889 11.5205 13.0294 11.4331 12.9419C11.3456 12.8545 11.2861 12.7432 11.262 12.6219C11.2379 12.5007 11.2503 12.375 11.2976 12.2608C11.3449 12.1466 11.425 12.049 11.5278 11.9803C11.6305 11.9117 11.7514 11.875 11.875 11.875C12.0408 11.875 12.1997 11.9408 12.3169 12.0581C12.4342 12.1753 12.5 12.3342 12.5 12.5Z" fill={fill} />
        </g>
        <defs>
            <clipPath id="clip0_1311_11910">
                <rect width="15" height="15" fill="white" />
            </clipPath>
        </defs>
    </svg>
}

export default BoxInstanceIcon;
