import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./reducers/user";
import { appSlice } from "./reducers/app";
import { apiDataSlice } from "./reducers/apiData";
import { dashboardSlice } from "./reducers/dashboard";
import { commonSlice } from "./reducers/common";
import { deviceControlSlice } from "./reducers/deviceControl";

import { authApi } from "./services/authApi";
import { omniApi } from "./services/omniApi";

const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [omniApi.reducerPath]: omniApi.reducer,
    user: userSlice.reducer,
    app: appSlice.reducer,
    dashboard: dashboardSlice.reducer,
    apiData: apiDataSlice.reducer,
    common: commonSlice.reducer,
    deviceControl: deviceControlSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(authApi.middleware, omniApi.middleware),
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export default store;
