import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import { decode } from "jsonwebtoken";
import { useEffect, useState } from "react";
import { setClientUsers, setUsers, setClients, disableLoading, setContracts, setModuleAccessMap, setSubsystems, setBuildings, setSites, setRoles, setPrivileges, setFavourite } from "@/lib/reducers/apiData";
import { useAgnosticGetQuery } from "@/lib/services/endpoints/newAPI/agnostic";
import { useGetClientContractsQuery, useGetClientBuildingsQuery, useGetClientRolesQuery } from "@/lib/services/endpoints/newAPI/customQueries";
import { setUser } from "@/lib/reducers/user";
import { initHiddenColumns } from "@/lib/reducers/common";
import { AppPayload } from "@/lib/types";
import { useGetUserDetailsQuery } from "@/lib/services/authApi";
import { Router } from "next/router";
import { useGetGrantedPrivilegesQuery } from "@/lib/services/endpoints/newAPI/user";
import { useEnsurePrivileges } from "@/lib/services/privilegeConfiguration";
import { isBefore, subDays } from 'date-fns'
import { IClient, ISite, IModuleAccessMap, IContractBuilding } from "@/lib/interfaces";
import { isArray } from "lodash";

interface Props {
  router: Router;
}

const DataLoader = ({ router }: Props) => {
  const dispatch = useAppDispatch();
  useEffect(() => { dispatch(initHiddenColumns()) })

  const accessToken = useAppSelector((state) => state.user.accessToken);
  const { loading, clients: rdxClients, buildings: rdxBuildings, contracts: rdxContracts } = useAppSelector((state) => state.apiData);
  const { building, contract, client } = useAppSelector((state) => state.apiData.favourite);

  //GET USER
  const [skipGetUser, setSkipGetUser] = useState<string>("");

  const userQuery = useGetUserDetailsQuery({
    accessToken: skipGetUser,
  }, { skip: skipGetUser === "" })

  //GET Subsystems
  const [skipGetSubsystems, setSkipGetSubsystems] = useState<boolean>(true);
  const subsystemsQuery = useAgnosticGetQuery({
    url: '/subsystems'
  }, { skip: skipGetSubsystems });

  //GET Users ONCE!
  const { data: usersData } = useAgnosticGetQuery({
    url: '/users',
    params: {
      'complete': '1'
    }
  }, { skip: accessToken === '' });

  useEffect(() => {
    if (isArray(usersData?.data)) {
      dispatch(disableLoading({ type: 'users' }))
      dispatch(setUsers({
        data: usersData.data
      }))

    }
  }, [usersData, dispatch])

  //GET client users ONCE!
  const { data: clientUsersData } = useAgnosticGetQuery({
    url: `/client/${client}/users`,
    params: {
      'complete': '1'
    }
  }, { skip: accessToken === '' || client === '0' });

  useEffect(() => {
    if (isArray(clientUsersData?.data)) {
      dispatch(disableLoading({ type: 'clientUsers' }))
      dispatch(setClientUsers({
        data: clientUsersData.data
      }))

    }
  }, [clientUsersData, dispatch])

  //GET Clients
  const [skipGetClients, setSkipGetClients] = useState<boolean>(true);
  const clients = useAgnosticGetQuery({
    url: '/clients',
    params: {
      complete: 1
    }
  }, { skip: skipGetClients });

  useEffect(() => {
    if (accessToken !== "") {
      setSkipGetClients(false);
    }

    if (!clients.isUninitialized && !clients.isLoading) {
      if (clients.data) {

        dispatch(setClients({
          data: clients.data.data
        }))
        dispatch(disableLoading({
          type: 'clients'
        }))
      }
    }
  }, [accessToken, clients, dispatch, router])

  //Tidy up client favourite:
  useEffect(() => {
    if (rdxClients) {
      if (!client || !rdxClients.find((c) => c.id == client)) {
        if (rdxClients.length > 0) {
          dispatch(setFavourite({ type: 'client', value: rdxClients[0].id }));
        }
      }
    }
  }, [client, rdxClients, dispatch]);



  useEffect(() => {
    if (accessToken !== "") {
      const decoded: AppPayload = decode(accessToken) as AppPayload;

      if (decoded.email) {
        setSkipGetUser(decoded?.token!)
      }

      if (
        !userQuery.isUninitialized && !userQuery.isLoading &&
        !clients.isUninitialized && !clients.isLoading
      ) {

        if (userQuery.data?.status === 'success') {
          const findCompany: undefined | IClient = clients.data?.data.find((item) => item.id === userQuery.data.data.primaryClient);

          dispatch(setUser({
            user: userQuery.data.data,
            company: findCompany
          }))
        }
      }

      setSkipGetSubsystems(false);
    }

    if (subsystemsQuery.isSuccess) {
      if (subsystemsQuery.data.status === 'success') {
        dispatch(
          setSubsystems({
            subsystems: subsystemsQuery.data.data
          })
        )

        dispatch(disableLoading({
          type: 'subsystems'
        }))
      }
    }
  }, [accessToken, subsystemsQuery, dispatch, userQuery, clients]);


  // GET Contracts
  const [contractIds, setContractIds] = useState<Array<string>>([]);

  const contractsQuery = useGetClientContractsQuery(contractIds, {
    skip: contractIds.length === 0,
  });

  useEffect(() => {
    const tempIds: Array<string> = [];
    if (clients.data?.data) {
      clients.data?.data.map((item) => {
        if (+item.isSupplier === 1 && +item.isContracted === 0) return;
        tempIds.push(item.id);
      });
      setContractIds(tempIds);
    }

    if (contractsQuery.isSuccess) {
      dispatch(setContracts({
        data: contractsQuery.data.contracts
      }))
      dispatch(disableLoading({
        type: 'contracts'
      }))
    }
  }, [clients, setContractIds, contractsQuery, dispatch]);

  //Tidy up contracts favourite:
  useEffect(() => {
    if (rdxContracts) {
      if (!contract || !rdxContracts.find((c) => c.id == contract)) {
        if (rdxContracts.length > 0) {
          dispatch(setFavourite({ type: 'contract', value: rdxContracts[0].id }));
        }
      }
    }
  }, [contract, rdxContracts, dispatch]);


  // GET Buildings -- for client not contract
  const buildingsQuery = useGetClientBuildingsQuery(rdxClients, {
    skip: loading.clients === true,
  });

  useEffect(() => {
    if (buildingsQuery.isSuccess) {

      dispatch(setBuildings({
        //only active buildings
        // data: buildingsQuery.data.buildings
        data: buildingsQuery.data.buildings?.filter((b) => b.isActive === '1')
      }))
      dispatch(disableLoading({
        type: 'buildings'
      }))
    }
  }, [buildingsQuery, dispatch]);

  //Tidy up building favourite:
  useEffect(() => {
    if (rdxBuildings) {
      if (!building || !rdxBuildings.find((c) => c.id == building)) {
        if (rdxBuildings.length > 0) {
          dispatch(setFavourite({ type: 'building', value: rdxBuildings[0].id }));
        }
      }
    }
  }, [building, rdxBuildings, dispatch]);


  // GET Roles -- for client
  const clientRolesQuery = useGetClientRolesQuery(rdxClients, {
    skip: loading.clients === true,
  });

  useEffect(() => {
    if (clientRolesQuery.isSuccess) {
      dispatch(setRoles({
        data: clientRolesQuery.data.roles
      }))
      dispatch(disableLoading({
        type: 'roles'
      }))
    }
  }, [clientRolesQuery, dispatch]);



  //GET PRIVILEGES
  const grantedPrivilegesQuery = useGetGrantedPrivilegesQuery({
    client,
    filters: {
      "complete": "1"
    }
  }, { skip: client === '0' || client === undefined || !client || accessToken === '' })
  //missing accessToken === '' on skip caused weird bug, unable to automatically reload modules then log in/out on diff users
  const { ensurePrivileges } = useEnsurePrivileges();

  useEffect(() => {
    const { data, isSuccess } = grantedPrivilegesQuery;


    if (isSuccess && data.status === 'success') {
      dispatch(disableLoading({ type: 'privileges' }))
      dispatch(setPrivileges({ privileges: data.data }))

      const lastRefresh = new Date(+data.lastRefresh! * 1000);
      const compareDate = subDays(new Date(), 1);

      if (isBefore(lastRefresh, compareDate)) {
        ensurePrivileges();
      }
    }

  }, [grantedPrivilegesQuery, dispatch,
    // ensurePrivileges
  ]);


  //New init data 
  const { data: contractSubsystemsData, isLoading: isCSLoading, isFetching: isCSFetching } = useAgnosticGetQuery({
    url: `/util/getContractSubsystems`,
    params: { complete: '1' }
  }, {
    skip: accessToken === ''
  })

  const { data: contractBuildingsData, isLoading: isCBLoading, isFetching: isCBFetching } = useAgnosticGetQuery({
    url: `/util/getContractBuildings`,
    params: { complete: '1' }
  }, {
    skip: accessToken === ''
  })

  useEffect(() => {
    const res: IModuleAccessMap = {}
    if (
      contractSubsystemsData?.data?.length > 0 && !isCSLoading && !isCSFetching &&
      contractBuildingsData?.data?.length > 0 && !isCBLoading && !isCBFetching
    ) {
      //1. Build client-contract-building map
      contractBuildingsData?.data?.map((item: IContractBuilding) => {
        if (!res[item.client]) {
          res[item.client] = {}
        }

        if (!res[item.client][item.contract]) {
          res[item.client][item.contract] = {
            'buildings': [],
            'subsystems': []
          }
        }
        if (item.available == "1") {
          res[item.client][item.contract]['buildings'].push(item.building)
        }
      })
      //2. Put subsystems in the map
      Object.keys(res).map((clientId) => {
        Object.keys(res[clientId]).map((contractId) => {
          contractSubsystemsData?.data?.map((item) => {
            if (item.client === clientId && item.contract === contractId) {
              const findSubsystem = subsystemsQuery?.data?.data?.find((subsystem) => subsystem.id == item.subsystem);
              res[clientId][contractId]['subsystems'].push(findSubsystem)
            }
          })
        })
      })
    }

    dispatch(setModuleAccessMap({ moduleAccessMap: res }))
  }, [contractSubsystemsData, isCSLoading, isCSFetching,
    contractBuildingsData, isCBLoading, isCBFetching,
    subsystemsQuery?.data?.data,
    dispatch
  ])
  //------------

  const { data: buildingGroupData, isLoading, isFetching } = useAgnosticGetQuery({
    url: `/util/getBuildingGroups`,
    params: { complete: '1' }
  }, {
    skip: accessToken === ''
  })

  useEffect(() => {
    if (rdxBuildings.length > 0 && !isLoading && !isFetching) {
      const tempSites: ISite[] = [];
      buildingGroupData?.data?.map((item) => {
        const site = {
          id: item.id,
          name: item.name,
          buildings: [],
          client: item.client
        }

        try {
          JSON.parse(item.buildings)?.map((bId: string) => {
            const findBuilding = rdxBuildings.find((b) => b.id === bId);
            if (findBuilding) {
              site.buildings.push(findBuilding);
            }
          })
        } catch (e) { }
        tempSites.push(site);
      })
      dispatch(setSites({ sites: tempSites }))

      //Set default site
      let siteId = 0;
      tempSites?.map((site) => {
        site?.buildings?.map(item => {
          if (item.id == building) {
            siteId = +site.id;
          }
        })
      })
      if (siteId > 0) {
        dispatch(setFavourite({ type: 'site', value: siteId }));
      }
    }
  }, [rdxBuildings, buildingGroupData, isLoading, isFetching, building, dispatch])


  //Redirect new Induction user
  useEffect(() => {
    if (accessToken !== "" && !loading.buildings) {
      if (rdxBuildings.length === 0) {
        router.push('/module/inductions')
      }
    }
  }, [rdxBuildings, accessToken, loading.buildings]) // eslint-disable-line react-hooks/exhaustive-deps


  return <></>

};

export default DataLoader;
