import { MenuIconProps } from "@/lib/types"

export const DlogsIcon = ({ fill, height = 20, width = 20 }: MenuIconProps) => {
    return <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2023_27911)">
            <path d="M11.2498 0H4.9999C3.75889 0.00175781 2.63623 0.737402 2.13994 1.875H1.2499C0.904785 1.875 0.625 2.15479 0.625 2.4999C0.625 2.84502 0.904785 3.1248 1.2499 3.1248H1.8748V4.3749H1.2499C0.904785 4.3749 0.625 4.65469 0.625 4.9998C0.625 5.34492 0.904785 5.62471 1.2499 5.62471H1.8748V6.8748H1.2499C0.904785 6.8748 0.625 7.15459 0.625 7.49971C0.625 7.84482 0.904785 8.12461 1.2499 8.12461H1.8748V9.37471H1.2499C0.904785 9.37471 0.625 9.65449 0.625 9.99961C0.625 10.3447 0.904785 10.6245 1.2499 10.6245H1.8748V11.8746H1.2499C0.904785 11.8746 0.625 12.1544 0.625 12.4995C0.625 12.8446 0.904785 13.1244 1.2499 13.1244H2.13994C2.63623 14.262 3.75889 14.9977 4.9999 14.9994H11.2498C12.9748 14.9974 14.3729 13.5993 14.3749 11.8743V3.1251C14.3729 1.4001 12.9748 0.00205078 11.2498 0ZM13.1248 11.8749C13.1248 12.9105 12.2855 13.7499 11.2498 13.7499H4.9999C3.96426 13.7499 3.1249 12.9105 3.1249 11.8749V3.1251C3.1249 2.08945 3.96426 1.2501 4.9999 1.2501H11.2498C12.2855 1.2501 13.1248 2.08945 13.1248 3.1251V11.8749Z" fill={fill} />
            <path d="M7.64853 11.0391H5.50195C5.16211 11.0391 4.88672 10.7637 4.88672 10.4238V4.57617C4.88672 4.23633 5.16211 3.96094 5.50195 3.96094H7.64853C8.72695 3.96094 9.65596 4.30723 10.3351 4.96289C10.9989 5.60361 11.3646 6.50479 11.3646 7.50029C11.3646 8.4958 10.9989 9.39697 10.3351 10.0377C9.65596 10.6931 8.72695 11.0396 7.64853 11.0396V11.0391ZM6.11719 9.80859H7.64853C8.40205 9.80859 9.03545 9.58154 9.48047 9.15176C9.90791 8.73926 10.1338 8.16797 10.1338 7.49971C10.1338 6.83145 9.90791 6.26016 9.48047 5.84766C9.03545 5.41816 8.40176 5.19082 7.64853 5.19082H6.11719V9.8083V9.80859Z" fill={fill} />
        </g>
        <defs>
            <clipPath id="clip0_2023_27911">
                <rect width="15" height="15" fill="white" />
            </clipPath>
        </defs>
    </svg>
}


export default DlogsIcon;