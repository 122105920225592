import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { IMobileMenu, IPage, IView } from "../interfaces";


interface IFilters {
  [key: string]: Array<{
    id: string;
    value: string;
  }>;

}
interface IHiddenColumns {
  [key: string]: Array<string>;
}

export interface AppSliceState {
  editHelpIcon: boolean;
  hideModuleMenuItem: boolean;
  hideAdminMenuItem: boolean;
  mobileMenu: IMobileMenu;
  manageFloorId: string;
  filters: IFilters;
  hiddenColumns: IHiddenColumns;
  pageState: { [key: string]: IView };
  subTitle: string;
  breadcrumbIdItem: { id: string, name: string };
}


type AppType = {
  breadcrumbIdItem: { id: string, name: string };
  editHelpIcon: boolean;
  hideModuleMenuItem: boolean;
  hideAdminMenuItem: boolean;

  mobileMenu: IMobileMenu;
  manageFloorId: string,
  filters: IFilters,
  hiddenColumns: IHiddenColumns;
  pageState: { [key in IPage]: IView }
  subTitle: string;
  clientBuildingCallback: () => void;
};

const initialState: AppType = {
  clientBuildingCallback: () => {
  },
  breadcrumbIdItem: { id: '', name: '' },
  editHelpIcon: false,
  hideAdminMenuItem: false,
  hideModuleMenuItem: false,
  mobileMenu: {
    isOpen: false,
    onClose: () => { },
    onOpen: () => { },
  },
  manageFloorId: '0',
  hiddenColumns: {},
  filters: {},
  pageState: {
    floorplans: 'list',
    assets: 'list',
    riskRadar: 'list',
    buildings: 'list',
    clients: 'list',
    users: 'list',
    contracts: 'list',
    maintenanceCalendar: 'list',
    quotes: 'list',
    labours: 'list',
    materials: 'list',
    tmCategories: 'list',
    tmProgrammes: 'list',
    tmPositions: 'list',
    tmPersonnel: 'list',
    dashboard: 'list',
    boxInstance: 'list',
    devices: 'list',
    deviceClasses: 'list',
    deviceClassIcons: 'list',
    clientUserRoles: 'list',
    roles: 'list',
    globalUserRoles: 'list',
    inductions: 'list',
  },
  subTitle: ''
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setClientBuildingCallback(state: AppType, action: PayloadAction<{ callback: () => void }>) {
      state.clientBuildingCallback = action.payload.callback;
    },
    setDataGridFilters(
      state: AppSliceState,
      action: PayloadAction<{ filters: IFilters, filterReduxPath: string }>
    ) {
      const { filters, filterReduxPath } = action.payload;
      //@ts-ignore
      state.filters[filterReduxPath] = filters;
    },
    setBreadcrumbIdItem(
      state: AppSliceState,
      action: PayloadAction<{ item: { id: string, name: string } }>
    ) {
      state.breadcrumbIdItem = action.payload.item;
    },

    toggleEditHelp(
      state: AppSliceState,
      action: PayloadAction<{ iconState: boolean }>
    ) {
      const { iconState } = action.payload;
      state.editHelpIcon = iconState;
    },

    setMobileInitState(
      state: AppSliceState,
      action: PayloadAction<{ mobileState: IMobileMenu }>
    ) {
      const { mobileState } = action.payload;
      state.mobileMenu = mobileState;
    },
    setPageState(
      state: AppSliceState,
      action: PayloadAction<{ page: IPage, state: IView }>
    ) {
      const { page, state: pageState } = action.payload;
      state.pageState[page] = pageState;
    },
    setPageSubtitle(state: AppSliceState, action: PayloadAction<{ title: string }>) {
      const { title } = action.payload;
      state.subTitle = title
    },


    setManageFloorId(
      state: AppSliceState,
      action: PayloadAction<{ floorId: string }>
    ) {
      state.manageFloorId = action.payload.floorId;
    },
    setHiddenColumn(
      state: AppSliceState,
      action: PayloadAction<{ page: string, column: string }>
    ) {
      const { column, page } = action.payload;


      //below can be removed most likely
      if (!state.hiddenColumns[page]) {
        state.hiddenColumns[page] = [];
      }


      if (state.hiddenColumns[page].includes(column)) {
        //REMOVE
        const index = state.hiddenColumns[page].indexOf(column);
        if (index > -1) {
          state.hiddenColumns[page].splice(index, 1);
        }
      } else {
        //ADD
        state.hiddenColumns[page].push(column)
      }

      //save to localStorage
      localStorage.setItem(`hiddenColumns`, JSON.stringify(state.hiddenColumns));
    },
    initHiddenColumns(state: AppSliceState) {
      try {
        state.hiddenColumns = JSON.parse(localStorage.getItem(`hiddenColumns`) || '{}');
      } catch (e) {
        state.hiddenColumns = {}
      }
    }
  },
});

export const {
  setDataGridFilters,
  setPageState,
  setHiddenColumn,
  initHiddenColumns,
  setManageFloorId,
  setMobileInitState,
  setPageSubtitle,
  toggleEditHelp,
  setBreadcrumbIdItem,
  setClientBuildingCallback
} = commonSlice.actions;

export default commonSlice.reducer;

