import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBoxDevice, IDeviceClassLoookupField } from "../interfaces";

type DeviceControlType = {
  isDeviceControlOpen: boolean,
  device: IBoxDevice | undefined;
  annotation: IDeviceClassLoookupField | undefined;
};

const initialState: DeviceControlType = {
  isDeviceControlOpen: false,
  device: undefined,
  annotation: undefined

};

export const deviceControlSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setDeviceControl(state: DeviceControlType, action: PayloadAction<{ device: IBoxDevice }>) { state.device = action.payload.device; },
    setAnnotation(state: DeviceControlType, action: PayloadAction<{ annotation: IDeviceClassLoookupField }>) { state.annotation = action.payload.annotation; },
    onDeviceControlOpen(state: DeviceControlType) { state.isDeviceControlOpen = true; },
    onDeviceControlClose(state: DeviceControlType) { state.isDeviceControlOpen = false; },
    // userLogout: () => initialState,
    // setAuthState(
    //   state: AuthSliceState,
    //   action: PayloadAction<{ authState: "IDLE" | "LOADING" }>
    // ) {
    //   state.authState = action.payload.authState;
    // },
  },
});

export const {
  setAnnotation,
  setDeviceControl,
  onDeviceControlClose,
  onDeviceControlOpen
} = deviceControlSlice.actions;

export default deviceControlSlice.reducer;
