import { MenuIconProps } from "@/lib/types"

export const HazardIcon = ({ fill, height = 20, width = 20 }: MenuIconProps) => {
    return <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1964_25923)">
            <path d="M7.50001 9.37546C7.33425 9.37546 7.17527 9.30961 7.05806 9.1924C6.94085 9.07519 6.87501 8.91622 6.87501 8.75045V3.75045C6.87501 3.58469 6.94085 3.42572 7.05806 3.30851C7.17527 3.1913 7.33425 3.12545 7.50001 3.12545C7.66577 3.12545 7.82474 3.1913 7.94195 3.30851C8.05916 3.42572 8.12501 3.58469 8.12501 3.75045V8.75045C8.12501 8.91622 8.05916 9.07519 7.94195 9.1924C7.82474 9.30961 7.66577 9.37546 7.50001 9.37546ZM7.99563 14.8505C9.34563 14.308 13.75 12.1748 13.75 7.52733V4.29545C13.7507 3.63859 13.5441 2.99825 13.1596 2.46565C12.7752 1.93305 12.2324 1.53535 11.6088 1.3292L7.69688 0.0323289C7.56917 -0.0107763 7.43084 -0.0107763 7.30313 0.0323289L3.39125 1.3292C2.76757 1.53535 2.22485 1.93305 1.84038 2.46565C1.45591 2.99825 1.24931 3.63859 1.25 4.29545V7.52733C1.25 11.6286 5.62813 14.1373 6.97063 14.8086C7.14043 14.8896 7.31773 14.9539 7.50001 15.0005C7.66948 14.9658 7.83539 14.9156 7.99563 14.8505ZM11.215 2.51545C11.5891 2.63946 11.9146 2.87819 12.1453 3.19773C12.3759 3.51726 12.5001 3.90136 12.5 4.29545V7.52733C12.5 11.3917 8.69563 13.2217 7.52938 13.6905C6.34938 13.1005 2.5 10.9123 2.5 7.52733V4.29545C2.49995 3.90136 2.62408 3.51726 2.85475 3.19773C3.08543 2.87819 3.41092 2.63946 3.785 2.51545L7.50001 1.2842L11.215 2.51545ZM7.50001 10.6255C7.37639 10.6255 7.25555 10.6621 7.15277 10.7308C7.04999 10.7995 6.96989 10.8971 6.92258 11.0113C6.87528 11.1255 6.8629 11.2512 6.88701 11.3724C6.91113 11.4936 6.97066 11.605 7.05806 11.6924C7.14547 11.7798 7.25684 11.8393 7.37807 11.8634C7.49931 11.8876 7.62498 11.8752 7.73918 11.8279C7.85339 11.7806 7.951 11.7005 8.01967 11.5977C8.08835 11.4949 8.12501 11.3741 8.12501 11.2505C8.12501 11.0847 8.05916 10.9257 7.94195 10.8085C7.82474 10.6913 7.66577 10.6255 7.50001 10.6255Z" fill={fill} />
        </g>
        <defs>
            <clipPath id="clip0_1964_25923">
                <rect width="15" height="15" fill="white" />
            </clipPath>
        </defs>
    </svg>
}


export default HazardIcon;