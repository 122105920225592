export * as HomeIcon from "./HomeIcon";
export * as BuildingsIcon from "./BuildingsIcon";
export * as ContractsIcon from "./ContractsIcon";
export * as DocumentsIcon from "./DocumentsIcon";
export * as SettingsIcon from "./SettingsIcon";
export * as ClientsIcon from "./ClientsIcon";
export * as UsersIcon from "./UsersIcon";
export * as AdminIcon from "./AdminIcon";
export * as SubsystemsIcon from "./SubsystemsIcon";
export * as RiskRadarIcon from "./RiskRadarIcon";
export * as CafmIcon from "./CafmIcon";
export * as ShiftRotaIcon from "./ShiftRotaIcon";
export * as DashboardIcon from "./DashboardIcon";
export * as BoxInstanceIcon from "./BoxInstanceIcon";
export * as DeviceIcon from "./DeviceIcon";
export * as AsbestosIcon from "./AsbestosIcon";

export * as MaintenanceCalendar from "./MaintenanceCalendar";
export * as DlogsIcon from "./DlogsIcon";
export * as HazardIcon from "./HazardIcon";
export * as MainPageIcon from "./MainPageIcon";
