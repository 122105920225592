import { IApiFilters, IClient, IRiskRadar, IStatus } from "@/lib/interfaces";
import { ObjectTypeAPI, omniApi, passParams } from "../../omniApi";
import _ from "lodash";

interface IRiskRadarParams {
  client: string;
  riskId?: string
}

interface IRiskRadarApiResponse {
  status: IStatus;
  data: Array<IRiskRadar>
}

export function queryParams({ filters }: IApiFilters) {
  const queryStrings: { [key: string]: string } = {};
  _.map(filters, (filterValue, filterKey) => {
    queryStrings[filterKey] = filterValue;
  })
  return encodeURIComponent(JSON.stringify(queryStrings));
}


export const riskRadarAPI = omniApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getRisks: builder.query<IRiskRadarApiResponse, IRiskRadarParams & IApiFilters>({
      query: ({ client, filters }) => ({
        url: `/client/${client}/risks${filters ? `?data=${queryParams({ filters })}` : ``}`,
        method: "get",
      }),
    }),
    getRisk: builder.query<IRiskRadarApiResponse, IRiskRadarParams & IApiFilters>({
      query: ({ client, riskId, filters }) => ({
        url: `/client/${client}/risk/${riskId}${filters ? `?data=${queryParams({ filters })}` : ``}`,
        method: "get",
      }),
    }),
  }),
});

export const {
  useGetRisksQuery,
  useGetRiskQuery
} = riskRadarAPI;