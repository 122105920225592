import { MenuIconProps } from "@/lib/types"

export const RiskRadarIcon = ({ fill }: MenuIconProps) => {
    return <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_128_2026)">
            <path d="M7.5 3.125C7.33424 3.125 7.17527 3.19085 7.05806 3.30806C6.94085 3.42527 6.875 3.58424 6.875 3.75V8.75C6.875 8.91576 6.94085 9.07473 7.05806 9.19194C7.17527 9.30915 7.33424 9.375 7.5 9.375C7.66576 9.375 7.82473 9.30915 7.94194 9.19194C8.05915 9.07473 8.125 8.91576 8.125 8.75V3.75C8.125 3.58424 8.05915 3.42527 7.94194 3.30806C7.82473 3.19085 7.66576 3.125 7.5 3.125Z" fill={fill} />
            <path d="M12.5226 15H2.47762C2.05524 15.001 1.63965 14.8937 1.27051 14.6884C0.901366 14.4832 0.590984 14.1867 0.368978 13.8274C0.146971 13.468 0.0207467 13.0578 0.00234683 12.6358C-0.016053 12.2138 0.0739859 11.7942 0.263873 11.4169L5.287 1.36814C5.47849 0.980987 5.76863 0.651137 6.1282 0.411828C6.48777 0.172518 6.90406 0.0322028 7.33512 0.0050191C7.8215 -0.0259332 8.30624 0.0867799 8.72907 0.32914C9.15189 0.571501 9.49414 0.932814 9.71325 1.36814L14.7364 11.4169C14.9252 11.7943 15.0143 12.2137 14.9954 12.6353C14.9765 13.0568 14.8502 13.4666 14.6284 13.8256C14.4066 14.1846 14.0967 14.4809 13.7281 14.6865C13.3596 14.892 12.9446 15 12.5226 15ZM1.25012 12.5269C1.25275 12.8512 1.38306 13.1615 1.61281 13.3904C1.84257 13.6194 2.15328 13.7485 2.47762 13.75H12.5226C12.847 13.7485 13.1577 13.6194 13.3874 13.3904C13.6172 13.1615 13.7475 12.8512 13.7501 12.5269C13.7498 12.3355 13.7044 12.1469 13.6176 11.9763L8.59575 1.92689C8.49416 1.72325 8.33783 1.55195 8.1443 1.43221C7.95077 1.31248 7.7277 1.24905 7.50012 1.24905C7.27255 1.24905 7.04948 1.31248 6.85594 1.43221C6.66241 1.55195 6.50608 1.72325 6.4045 1.92689L1.38137 11.9763C1.29502 12.147 1.25005 12.3356 1.25012 12.5269Z" fill={fill} />
            <path d="M8.125 11.25C8.125 10.9048 7.84518 10.625 7.5 10.625C7.15482 10.625 6.875 10.9048 6.875 11.25C6.875 11.5952 7.15482 11.875 7.5 11.875C7.84518 11.875 8.125 11.5952 8.125 11.25Z" fill={fill} />
            <g clipPath="url(#clip1_128_2026)">
                <path d="M126.43 138H23.5696C19.2444 138.01 14.9888 136.911 11.2087 134.809C7.42872 132.707 4.25041 129.672 1.97706 125.992C-0.296282 122.312 -1.58882 118.112 -1.77724 113.791C-1.96565 109.469 -1.04365 105.172 0.90079 101.309L52.3376 -1.59055C54.2984 -5.55503 57.2695 -8.9327 60.9515 -11.3832C64.6335 -13.8338 68.8963 -15.2706 73.3104 -15.5489C78.2909 -15.8659 83.2547 -14.7117 87.5844 -12.2299C91.9141 -9.74817 95.4188 -6.04832 97.6624 -1.59055L149.099 101.309C151.032 105.173 151.946 109.468 151.752 113.785C151.558 118.102 150.265 122.298 147.993 125.974C145.722 129.65 142.549 132.684 138.775 134.789C135.001 136.894 130.752 137.999 126.43 138ZM11 112.675C11.0269 115.996 12.3612 119.173 14.714 121.518C17.0667 123.862 20.2483 125.185 23.5696 125.2H126.43C129.752 125.185 132.933 123.862 135.286 121.518C137.639 119.173 138.973 115.996 139 112.675C138.997 110.715 138.532 108.784 137.643 107.037L86.2192 4.13106C85.179 2.04574 83.5781 0.291601 81.5964 -0.934473C79.6146 -2.16055 77.3304 -2.81003 75 -2.81003C72.6696 -2.81003 70.3854 -2.16055 68.4036 -0.934473C66.4218 0.291601 64.821 2.04574 63.7808 4.13106L12.344 107.037C11.4597 108.785 10.9993 110.716 11 112.675Z" fill={fill} />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_128_2026">
                <rect width="15" height="15" fill={fill} />
            </clipPath>
            <clipPath id="clip1_128_2026">
                <rect width="153.6" height="153.6" fill={fill} transform="translate(-1.7998 -15.6001)" />
            </clipPath>
        </defs>
    </svg>
}

export default RiskRadarIcon;
