import { MenuIconProps } from "@/lib/types"

export const UsersIcon = ({ fill, cursor }: MenuIconProps) => {
    return <svg
        cursor={cursor}
        width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_608_6614)">
            <path d="M15.832 0H4.16797C1.86719 0.00390625 0.00390625 1.86719 0 4.16797V15.8359C0.00390625 18.1328 1.86719 19.9961 4.16797 20H15.8359C18.1367 19.9961 20 18.1328 20.0039 15.832V4.16797C19.9961 1.86719 18.1328 0.00390625 15.832 0ZM14.168 18.332H5.83203V17.5C5.83203 15.1992 7.69922 13.332 10 13.332C12.3008 13.332 14.168 15.1992 14.168 17.5V18.332ZM18.3359 15.832H18.332C18.332 17.2109 17.2109 18.332 15.832 18.332V17.5C15.832 14.2773 13.2188 11.668 10 11.668C6.78125 11.668 4.16797 14.2773 4.16797 17.5V18.332C2.78906 18.332 1.66797 17.2109 1.66797 15.832V4.16797C1.66797 2.78906 2.78906 1.66797 4.16797 1.66797H15.8359C17.2148 1.66797 18.3359 2.78906 18.3359 4.16797V15.832Z" fill={fill} />
            <path d="M10 3.33203C8.16016 3.33203 6.66797 4.82422 6.66797 6.66406C6.66797 8.50391 8.16016 10 10 10C11.8398 10 13.332 8.50781 13.332 6.66797C13.332 4.82812 11.8398 3.33203 10 3.33203ZM10 8.33203C9.07813 8.33203 8.33203 7.58594 8.33203 6.66406C8.33203 5.74219 9.07813 5 10 5C10.9219 5 11.668 5.74609 11.668 6.66797C11.668 7.58984 10.9219 8.33203 10 8.33203Z" fill={fill} />
        </g>
        <defs>
            <clipPath id="clip0_608_6614">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
}

export default UsersIcon;
